.App {
	text-align: center;
}

h2 {
	font-size: 3em;
}

h3 {
	font-size: 1.5em;
}
