:root {
	--primary-clr: #fe4174;
	--primary-alpha-clr: #fe417349;
	--high-clr: #df003c;
	--high-dark-clr: #36101a;
	--dark-clr: #262526;
	--darker-clr: #141414;
	--dark-alpha-clr: #262526b7;
	--soft-clr: #ffa7be;
}

::selection {
	color: var(--darker-clr);
	background-color: var(--high-clr);
}

html {
	scroll-behavior: smooth;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	background-color: var(--dark-clr);
	color: var(--soft-clr);
	font-family: 'Roboto', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--primary-clr);
}

button {
	font-family: 'Poppins', sans-serif;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
